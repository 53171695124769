<!--提现设置-->
<template>
  <div style="padding: 20px;">
    <div style="display: flex;justify-content: space-between;align-items: center;">
      <div>
        <span style="font-size: 24px;font-weight: 500;color: #707070;">提现设置</span>
      </div>
      <div>

      </div>
    </div>

    <el-card shadow="never" style="margin-top: 20px;">
      <el-form ref="form" :model="form" label-width="170px">
        <div style="border-bottom: 1px solid #EEEEEE;">
          <div class="xuanxianglist">
            <div v-for="(item,index) in list" :key="index" class="kuai">
              <div class="xuanxianglistactive"  :class="{xuanxianglistactive1:index==isShow}" @click="activeItem(item,index)">
                <img :src="item.img" alt="" style="width: 15px;height: 15px;margin-right: 5px;vertical-align: middle;">
                <span style="vertical-align: middle;">{{item.name}}</span>
              </div>
            </div>
          </div>
        </div>

        <div v-if="isShow == 0">
          <el-row :gutter="15" style="margin-top: 20px;">
            <el-col :span="24">
              <el-form-item label="开启余额提现：">
                <!--<el-radio v-model="config.facilitator.balance_switch" :label="true">开启</el-radio>-->
                <!--<el-radio v-model="config.facilitator.balance_switch" :label="true">关闭</el-radio>-->
                <el-switch v-model="config.facilitator.balance_switch"></el-switch>
              </el-form-item>
            </el-col>
          </el-row>
          <div v-if="config.facilitator.balance_switch == true">
            <el-row :gutter="15">
              <el-col :span="24">
                <el-form-item label="提现到支付宝：">
                  <!--<el-radio v-model="form.radio" label="1">开启</el-radio>-->
                  <!--<el-radio v-model="form.radio" label="2">关闭</el-radio>-->
                  <el-switch v-model="config.facilitator.ali_balance"></el-switch>
                </el-form-item>
              </el-col>
            </el-row>
            <!--<el-row :gutter="15">-->
            <!--  <el-col :span="24">-->
            <!--    <el-form-item label="提现到微信：">-->
            <!--      <el-switch v-model="config.facilitator.wechat_balance"></el-switch>-->
            <!--    </el-form-item>-->
            <!--  </el-col>-->
            <!--</el-row>-->
            <!--<el-row :gutter="15">-->
            <!--  <el-col :span="24">-->
            <!--    <el-form-item label="提现到银行卡：">-->
            <!--      <el-switch v-model="config.facilitator.bank_balance"></el-switch>-->
            <!--    </el-form-item>-->
            <!--  </el-col>-->
            <!--</el-row>-->
            <el-row :gutter="15">
              <el-col :span="8">
                <el-form-item label="提现手续费：">
                  <el-radio v-model="config.facilitator.service_charge_mode" label="fixed">固定金额</el-radio>
                  <el-radio v-model="config.facilitator.service_charge_mode" label="ratio">手续费比例</el-radio>
                </el-form-item>
              </el-col>
              <!--<el-col :span="3" v-if="isShow === 0">-->
              <!--  <div>-->
              <!--    <div v-for="(item,index) in activeList" :key="index" class="active" :class="{active1:index === isActive}" @click="onClick(item,index)">-->
              <!--      {{item.label}}-->
              <!--    </div>-->
              <!--  </div>-->
              <!--</el-col>-->
              <el-col :span="8" v-if="config.facilitator.service_charge_mode == 'fixed'">
                <el-form-item label="固定金额">
                  <el-input clearable placeholder="请输入" v-model="config.facilitator.service_charge_money" style="width: 280px!important;">
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="config.facilitator.service_charge_mode == 'ratio'">
                <el-form-item label="手续费比例">
                  <el-input clearable placeholder="请输入" v-model="config.facilitator.service_charge_money" style="width: 280px!important;">
                    <template slot="append">%</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <el-col :span="24">
                <el-form-item label="单次最小金额限制：" v-if="isShow === 0">
                  <el-input clearable placeholder="提现最小金额值" v-model="config.facilitator.monetary_limitation" style="width: 280px!important;" />
                </el-form-item>
                <el-form-item label="提现最小金额限制：" v-if="isShow === 1">
                  <el-input clearable placeholder="提现最小金额值" v-model="config.facilitator.monetary_limitation" style="width: 280px!important;" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <el-col :span="24">
                <el-form-item label="单次最大提现金额：">
                  <el-input clearable placeholder="提现最大金额值" v-model="config.facilitator.once_monetary_limitation" style="width: 280px!important;" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <el-col :span="24">
                <el-form-item label="每日提现次数：">
                  <el-input clearable placeholder="请输入次数" v-model="config.facilitator.everyday_count" style="width: 280px!important;" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <el-col :span="12">
                <!-- <el-form-item label="是否提供发票：">
                  <el-switch v-model="config.facilitator.is_invoice" active-text="是" inactive-text="否"></el-switch>
                </el-form-item> -->
              </el-col>
              <el-col :span="12" v-if="config.facilitator.is_invoice == '1'">
                <!--<el-form-item label="费率：">-->
                <!--  <el-input-number placeholder="请输入费率" v-model="config.facilitator.invoice_parities" controls-position="right" :min="1" :max="10"></el-input-number>-->
                <!--  <span>%</span>-->
                <!--</el-form-item>-->
              </el-col>
            </el-row>
            <el-row :gutter="15" v-if="config.facilitator.is_invoice == '1'">
              <el-col :span="12" >
                <el-form-item label="类型：">
                  <el-radio v-model="form.radio" label="1">普票</el-radio>
                  <el-radio v-model="form.radio" label="2">专票</el-radio>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="费率：">
                  <el-input-number placeholder="请输入费率" v-model="config.facilitator.invoice_parities" controls-position="right" :min="1" :max="10"></el-input-number>
                  <span>%</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="周期：">
                  <el-input-number placeholder="请输入天数" v-model="config.facilitator.period" controls-position="right" :min="1" :max="3"></el-input-number>
                  <span>天</span>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>


        <div v-if="isShow == 1">
          <el-row :gutter="15" style="margin-top: 20px;">
            <el-col :span="24">
              <el-form-item label="开启余额提现：">
                <!--<el-radio v-model="config.facilitator.balance_switch" :label="true">开启</el-radio>-->
                <!--<el-radio v-model="config.facilitator.balance_switch" :label="true">关闭</el-radio>-->
                <el-switch v-model="config.consumer.balance_switch"></el-switch>
              </el-form-item>
            </el-col>
          </el-row>
          <div v-if="config.consumer.balance_switch == true">
            <el-row :gutter="15">
              <el-col :span="24">
                <el-form-item label="提现到支付宝：">
                  <!--<el-radio v-model="form.radio" label="1">开启</el-radio>-->
                  <!--<el-radio v-model="form.radio" label="2">关闭</el-radio>-->
                  <el-switch v-model="config.facilitator.ali_balance"></el-switch>
                </el-form-item>
              </el-col>
            </el-row>
            <!--<el-row :gutter="15">-->
            <!--  <el-col :span="24">-->
            <!--    <el-form-item label="提现到微信：">-->
            <!--      <el-switch v-model="config.facilitator.bank_balance"></el-switch>-->
            <!--    </el-form-item>-->
            <!--  </el-col>-->
            <!--</el-row>-->
            <!--<el-row :gutter="15">-->
            <!--  <el-col :span="24">-->
            <!--    <el-form-item label="提现到银行卡：">-->
            <!--      <el-switch v-model="config.consumer.bank_balance"></el-switch>-->
            <!--    </el-form-item>-->
            <!--  </el-col>-->
            <!--</el-row>-->
            <el-row :gutter="15">
              <el-col :span="8">
                <el-form-item label="提现手续费：">
                  <el-radio v-model="config.consumer.service_charge_mode" label="fixed">固定金额</el-radio>
                  <el-radio v-model="config.consumer.service_charge_mode" label="ratio">手续费比例</el-radio>
                </el-form-item>
              </el-col>
              <!--<el-col :span="3" v-if="isShow === 0">-->
              <!--  <div>-->
              <!--    <div v-for="(item,index) in activeList" :key="index" class="active" :class="{active1:index === isActive}" @click="onClick(item,index)">-->
              <!--      {{item.label}}-->
              <!--    </div>-->
              <!--  </div>-->
              <!--</el-col>-->
              <el-col :span="8" v-if="config.consumer.service_charge_mode == 'fixed'">
                <el-form-item label="固定金额">
                  <el-input clearable placeholder="请输入" v-model="config.consumer.service_charge_money" style="width: 280px!important;">
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="config.consumer.service_charge_mode == 'ratio'">
                <el-form-item label="手续费比例">
                  <el-input clearable placeholder="请输入" v-model="config.consumer.service_charge_money" style="width: 280px!important;">
                    <template slot="append">%</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <el-col :span="24">
                <el-form-item label="提现金额限制：" v-if="isShow === 0">
                  <el-input clearable placeholder="提现最小金额值" v-model="config.consumer.monetary_limitation" style="width: 280px!important;" />
                </el-form-item>
                <el-form-item label="提现最小金额限制：" v-if="isShow === 1">
                  <el-input clearable placeholder="提现最小金额值" v-model="config.consumer.monetary_limitation" style="width: 280px!important;" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <el-col :span="24">
                <el-form-item label="单次最大提现金额：">
                  <el-input clearable placeholder="提现最大金额值" v-model="config.consumer.once_monetary_limitation" style="width: 280px!important;" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <el-col :span="24">
                <el-form-item label="每日提现次数：">
                  <el-input clearable placeholder="请输入次数" v-model="config.consumer.everyday_count" style="width: 280px!important;" />
                </el-form-item>
              </el-col>
            </el-row>
            <!--<el-row :gutter="15">-->
            <!--  <el-col :span="12">-->
            <!--    <el-form-item label="是否提供发票：">-->
            <!--      &lt;!&ndash;<el-radio v-model="form.radio" label="1">是</el-radio>&ndash;&gt;-->
            <!--      &lt;!&ndash;<el-radio v-model="form.radio" label="2">否</el-radio>&ndash;&gt;-->
            <!--      <el-switch v-model="config.consumer.is_invoice" active-text="是" inactive-text="否"></el-switch>-->
            <!--    </el-form-item>-->
            <!--  </el-col>-->
            <!--  <el-col :span="12">-->
            <!--    <el-form-item label="费率：">-->
            <!--      <el-input-number placeholder="请输入费率" v-model="config.consumer.invoice_parities" controls-position="right" :min="1" :max="10"></el-input-number>-->
            <!--    </el-form-item>-->
            <!--    &lt;!&ndash;<el-input clearable placeholder="请输入费率" v-model="form.input2" style="width: 280px!important;" />&ndash;&gt;-->
            <!--  </el-col>-->
            <!--</el-row>-->
            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="周期：">
                  <el-input-number placeholder="请输入天数" v-model="config.consumer.period" controls-position="right" :min="1" :max="3"></el-input-number>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>


      </el-form>
    </el-card>

    <div style="margin-top: 20px;text-align: center;">
      <el-button
        style="margin-left: 20px;width: 240px;"
        type="primary"
        v-auths="[`${$config.uniquePrefix}operate:withdrawalSettings:put`]"
        @click="save()">提交
      </el-button>
    </div>

  </div>
</template>

<script>
import {get_withdrawal_setup,set_withdrawal_setup} from '@/api/operate'

export default {
  data(){
    return{
      form:{
        checkList:[],
      },

      //数据
      list:[
        {
          name:'服务商提现',
          img:require('../../../assets/images/home/yunying/icon1active.png')
        },
        {
          name:'司机提现',
          img:require('../../../assets/images/home/yunying/icon2.png')
        },
      ],
      isShow:0,

      activeList:[
        {
          label:'公户'
        },
        {
          label: '微信'
        }
      ],
      isActive:0,

      // 数据配置
      config:{},

    }
  },

  mounted() {
    //调用获取提现配置
    this.getConfig();
  },

  methods:{
    //选择类型
    activeItem(item,index){
      this.isShow = index;
      if(item.name === '服务商提现'){
        this.list[0].img = require('../../../assets/images/home/yunying/icon1active.png');
        this.list[1].img = require('../../../assets/images/home/yunying/icon2.png')
      }else if(item.name === '司机提现'){
        this.list[0].img = require('../../../assets/images/home/yunying/icon1.png');
        this.list[1].img = require('../../../assets/images/home/yunying/icon2active.png')
      }
    },

    onClick(item,index){
      this.isActive = index;
    },

    //获取配置
    getConfig(){
      get_withdrawal_setup().then(res => {
        console.log(res);
        this.config = res.data;
      })
    },

    // 点击提交
    save(){
      let data = {
        data: this.config
      }
      set_withdrawal_setup(data).then(res => {
        console.log(res)
        if(res.code == 200){
          this.$message.success(res.msg)
        }else {
          this.$message.success(res.msg)
        }
      })
    },


  },

}
</script>

<style lang="scss" scoped>
.xuanxianglist{
  .kuai{
    margin: 10px;
    display: inline-block;
  }
  .xuanxianglistactive{
    cursor: pointer;
    background: #E8E8E8;
    border: 1px solid #dadada;
    border-radius: 6px;
    display: inline-block;
    width: 100%;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
    font-weight: 400;
  }
  .xuanxianglistactive1{
    background: #FF9B05;
    border: 1px solid #FF9B05;
    border-radius: 6px;
    color: #FFFFFF;
  }
}


.active{
  display: inline-block;
  padding: 5px 10px 5px 10px;
  background: #3f4157;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  margin-right: 10px;
  cursor: pointer;
}
.active1{
  background: #47D170;
}
</style>
